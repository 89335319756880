import { useCallback, useEffect, useState } from 'react'
import { toast } from '~/components/ui'
import { ParcelData } from '~/models'
import { SERVE_ACCESS_TOKEN } from '~/utils'

const SERVE_PARCELS_URL = 'https://serve.resimplifi.com/parcels' // 'http://localhost:3000/parcels'

export async function getParcel(parcelApn: string) {
  const response = await fetch(
    `${SERVE_PARCELS_URL}/${parcelApn}?token=${SERVE_ACCESS_TOKEN}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  if (response.status === 200) {
    const data = await response.json()
    return data
  } else if (response.status === 404) {
    return null
  } else {
    console.error('Error fetching parcel', response.status, response.body)
    return { error: 'Error fetching parcel' }
  }
}

export async function searchParcels(
  latitude: string | number,
  longitude: string | number
) {
  const body = {
    latitude: typeof latitude === 'number' ? latitude : parseFloat(latitude),
    longitude:
      typeof longitude === 'number' ? longitude : parseFloat(longitude),
  }
  const response = await fetch(
    `${SERVE_PARCELS_URL}?token=${SERVE_ACCESS_TOKEN}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  if (response.status === 200) {
    const data = await response.json()
    return data
  } else {
    console.error('Error fetching parcels', response.status, response.body)
    return { error: 'Error fetching parcels' }
  }
}

export function useParcelsSearch({
  latitude,
  longitude,
  showError,
}: {
  latitude?: string | number
  longitude?: string | number
  showError?: true
}) {
  const [parcels, setParcels] = useState<ParcelData[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const internalSearchParcels = async () => {
    if (latitude && longitude) {
      setIsLoading(true)
      const data = await searchParcels(latitude, longitude)
      if (data.error && showError) {
        toast({
          variant: 'destructive',
          title: 'Error Fetching Parcel',
          description:
            'There was a problem fetching the parcel data. Please try again or contact support if the problem persists.',
        })
      } else {
        setParcels(data)
      }
      setIsLoading(false)
    }
  }

  useEffect(() => {
    internalSearchParcels()
  }, [latitude, longitude])

  return { parcels, isLoading }
}

export function useGetParcel({
  parcelApn,
  showError = false,
}: {
  parcelApn?: string
  showError?: boolean
}) {
  const [parcel, setParcel] = useState<ParcelData | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const _getParcel = useCallback(async () => {
    if (parcelApn) {
      setIsLoading(true)
      const data = await getParcel(parcelApn)
      if (data && data.error && showError) {
        toast({
          variant: 'destructive',
          title: 'Error Fetching Parcel',
          description:
            'There was a problem fetching the parcel. Please try again or contact support if the problem persists.',
        })
      } else {
        setParcel(data)
      }
      setIsLoading(false)
    }
  }, [parcelApn])

  useEffect(() => {
    _getParcel()
  }, [parcelApn])

  return { parcel, isLoading }
}

export function useGetParcels({
  parcelApns,
  enabled,
}: {
  parcelApns: string[]
  enabled: boolean
}) {
  const [parcels, setParcels] = useState<ParcelData[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const _getParcels = useCallback(async () => {
    setIsLoading(true)
    const parcelPromises = parcelApns.map((parcelApn) => getParcel(parcelApn))
    const parcelData = await Promise.all(parcelPromises)
    setParcels(parcelData)
    setIsLoading(false)
  }, [parcelApns])

  useEffect(() => {
    if (enabled && parcelApns.length > 0 && parcels.length === 0) {
      _getParcels()
    }
  }, [enabled])

  return { parcels, isLoading }
}
